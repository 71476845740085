html, body, #root {
  height: 100%;
  margin: 0;
  overflow: hidden; /* This will hide the scrollbar which may appear because of the fog effect */
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
