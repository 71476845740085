@keyframes flickerAnimation {
  0% { opacity: 1; }
  50% { opacity: 0.4; }
  100% { opacity: 1; }
}

.coming-soon {
  animation: flickerAnimation 1s infinite linear;
  font-size: xxx-large;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  font-family: 'Honk', sans-serif; /* This applies the font to the entire .App */
}

h1 {
  font-family: 'Honk', sans-serif;
  color: white;
  text-align: center;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.x-logo {
  height: 50px; /* Adjust the size as needed */
  width: auto;
}
